import type { GetProductsResponse } from "@dtp/membership-service-types";
import { useEffect } from "react";
import {
  api,
  composeClient,
  createRestClient,
  sendJson,
  withAuth,
  withBaseUri,
  withHeader,
} from "../../api/ApiClient";
import { useAutoLoadingClientBase, useClientBase } from "../../api/ClientBase";
import type { ContractingParty } from "#vehicle-contract/common/lib/model/contract/ContractingParty";
import type { ContractToken } from "../../contract/model/ContractViewModel";

const getContactInfo = (token: string) =>
  api.get<ContractingParty>(`contract/token/${token}/contactInfo`);

export function useGetContractingPartyByToken(contractToken?: ContractToken) {
  const clientBase = useClientBase(getContactInfo);
  const { fetch } = clientBase;
  useEffect(() => {
    if (contractToken) fetch(contractToken);
  }, [fetch, contractToken]);

  return clientBase;
}

export const MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER = "2020-1121";
export const MEMBERSHIP_NO_ROADASSISTANCE_PRODUCT_NUMBER = "1121";
export const TRIAL_MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER = "6111";

export const PRODUCT_WHITELIST = [
  MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER,
  MEMBERSHIP_NO_ROADASSISTANCE_PRODUCT_NUMBER,
];

export const options =
  typeof window !== "undefined" &&
  window.location.host === "kjopekontrakt.naf.no"
    ? {
        host: "https://api.naf.no/dtp-contenthub-membershipapi",
        subscriptionKey: "1fc5e7c364104d5db53afe1423dd066c",
      }
    : {
        host: "https://test-api2.naf.no/contenthub-membership",
        subscriptionKey: "f810c9d4673948f49be314afb6ce7e71",
      };

export const membershipServiceApi = createRestClient(
  composeClient(
    sendJson,
    withBaseUri(options.host),
    withHeader("Ocp-Apim-Subscription-Key", options.subscriptionKey),
    withAuth,
  ),
);

function getMembershipProducts() {
  return membershipServiceApi.get<GetProductsResponse>("v2/products");
}

export function useMembershipServiceProducts() {
  return useAutoLoadingClientBase(getMembershipProducts);
}
